// SmallButtonIconOnly
$smallButtonIconOnlySize: 44px;
$smallButtonIconOnlySizeSP: 32px;
$smallButtonIconOnlyIconSize: 24px;

// ScrollBar
$scrollBarWidth: 4px;
$scrollBarMinHeight: 180px;

// LargeButton
$largeButtonGap: 4px;
$largeButtonOuterCircleSize: 80px;
$largeButtonMiddleCircleSize: 72px;
$largeButtonInnerCircleSize: 64px;
$largeButtonIconSize: 35px;
$largeButtonIconOnlyBorderWidth: 1px;

// PrimaryButton
$primaryButtonWidth: 200px;
$primaryButtonSPWidth: 130px;
$primaryButtonMaxWidth: 360px;

// NoImageBackground
$noImageBackgroundIconSize: 32px;

// SectionControlButton
$sectionControlButtonGap: 24px;

// LargeButtonIconOnly
$largeButtonIconOnlySize: 200px;
$largeButtonIconOnlyCircleWhiteSize: 184px;
$largeButtonIconOnlyCircleGraySize: 168px;
$largeButtonIconOnlyIconSize: 64px;

// RoomEntranceBody
$roomEntranceBodyGap: 32px;
$roomEntranceBodyGapS: 24px;
$roomEntranceBodyGapSS: 18px;
$roomEntranceBodyWidth: 550px;

// TODO Rename $roomEntranceBodyWidthFixed after marging
$roomEntranceBodyWidthFixed: 400px;
$roomEntranceBodyRoomNameGap: 8px;
$roomEntranceBodyTextsGap: 4px;
$roomEntranceBodyButtonsGap: 8px;
$roomEntranceBodyButtonHeight: 48px;

// RoomPermissionErrorBody
$roomPermissionErrorBodyGap: 10px;
$roomPermissionErrorBodyRoomNameGap: 8px;

// SecondaryButton
$secondaryButtonWidth: 200px;
$secondaryButtonWidthSP: 130px;

// SelectorButton
$selectorButtonOptionHeight: 40px;
$selectorButtonOptionTextMaxWidth: 256px;
$selectorButtonIconSize: 24px;
$selectorButtonOptionsListMaxWidth: 320px;
$selectorButtonOptionsListHeight: 200px;

// PageNotFound
$pageNotFoundMinSize: 768px;

// PageError
$pageErrorMinSize: 768px;

// DialogFooter
$dialogFooterGap: 8px;
$dialogFooterHeight: 88px;
$dialogFooterSPHeight: 72px;
$dialogFooterPadding: 16px;

// DialogDeviceSettingBody
$dialogDeviceSettingBodyStreamAreaHeight: 365px;
$dialogDeviceSettingBodyStreamAreaSPHeight: 182px;
$dialogDeviceSettingBodyPadding: 16px;
$dialogDeviceSettingLabelGap: 4px;
$dialogDeviceSettingIconSize: 24px;
$dialogDeviceSettingWrapperSelectorWidth: 320px;

// DialogTextBody
$dialogTextBodyMaxHeight: 48px;

// DialogNotificationTextBody

$dialogNotificationTextBodyWidth: 358px;

// StreamVideo
$streamVideoCameraOffIconSize: 32px;

// Servicing
$servicingHeaderMinHeight: 50px;
$servicingSectionControlButtonsBottomDistance: 44px;
$servingInCameraWidth: 320px;
$servingInCameraWidthSP: 82px;
$servingInCameraHeight: 180px;
$servingInCameraHeightSP: 177px;
$servingInCameraMargin: 10px;
$servingInCameraMarginSP: 20px;
$servicingMonitoringMessageTopPosition: 10px;

// TODO: スマホ暫定対応、後で修正
// tablet size
$tabletMinSize: 768px;

// TODO: スマホ暫定対応、後で修正
// smartphone size
$spWidth: 430px;
$spMinWidth: 375px;
$spMinHeight: 667px;

// TODO: 埋め込みウィジェット表示ずれの暫定対応
$spWidthWidget: 350px;
$servicingHeaderHeightSP: 54px;

// FooterGraphic
$footerGraphicWidth: 1920px;

// Dialog common
$dialogWidth: 626px;
$dialogWidthSP: 290px;

// DialogAlert
$dialogAlertWidth: 550px;
$dialogAlertWidthSP: 300px;

// DialogConnectionError
$dialogTextIconBodyWidth: 550px;
$dialogTextIconBodySizeIcon: 40px;

// SubListCell
$subListCellHeight: 34px;
$subListCellIconHeight: 20px;
$subListCellIconWidth: 20px;
$subListCellLargeButtonGap: 8px;
$subListCellPaddingLeft: 10px;
$subListCellPaddingRight: 10px;

// ErrorContent
$errorContentGap: 32px;
$errorContentImageHeight: 250px;
$errorContentImageWidth: 300px;

// DialogLoginButton
$dialogLoginButtonsHeight: 88px;
$dialogLoginButtonsPadding: 16px;

// DialogLoginBody
$dialogLoginBodyPadding: 16px;
$dialogLoginBodyGap: 16px;
$dialogLoginBodyErrorMessageGap: 5px;

// DialogHeader
$dialogHeaderHeight: 64px;

// DialogNotificationHeader
$dialogNotificationHeaderWidth: 358px;
$dialogNotificationHeaderHeight: 64px;

// InputLabelWithTextForm
$inputLabelWithTextFormLabelGap: 4px;
$inputLabelWithTextFormIconSize: 24px;
$inputLabelWithTextFormLabelAndInputGap: 16px;

// TextField
$textFieldWidth: 320px;
$textFieldHeight: 40px;
$textFieldIconSize: 24px;

// Border
$borderWidth2: 2px;

// Sub Menu List
$subMenuListWidth: 160px;

// InCamera
$inCameraButtonCloseMargin: 4px;

// RemoteCameraArea
$remoteCameraAreaPortraitWidth: 180px;
$remoteCameraAreaPortraitHeight: 320px;
$remoteCameraAreaLandscapeWidth: 320px;
$remoteCameraAreaLandscapeHeight: 180px;
$remoteCameraAreaMaxDeviceHeight: 1366px;

// LabelMicAnimation
$dialogScreenAreaMicAnimationSize: 44px;
$IconMicAnimationSize: 32px;

// EndOfServiceBody
$endOfServiceBodyGap: 32px;

// LoadingPage
$loadingPageElementGap: 24px;
$loadingPageContentWidth: 256px;
$loadingPageIconLoadingSize: 32px;
$loadingPageIconContainerSize: 64px;

// Template
$templateMinWidth: 1366px;
$templateMinHeight: 768px;

// Login
$loginPaddingTop: 152px;
$loginPaddingBottom: 6px;

// DevicePermissionGraphic
$DevicePermissionGraphicWidth: 260px;
$DevicePermissionGraphicHeight: 100px;

// Template
$templateMinWidth: 1366px;
$templateMinHeight: 768px;

// Login
$loginPaddingTop: 152px;
$loginPaddingBottom: 6px;

// EndOfServiceBody
$copyrightPaddingTop: 60px;

// SubtitlesContent
$subtitlesContentTop: 50px;
$subtitlesContentBottom: 90px;
$subtitlesContentTextPadding: 4px;
$subtitlesInitialFontSize: 22px;
$subtitlesInitialDoubledFontSize: 44px;

// widgetMiniButton
$widgetMiniButtonSize: 40px;
$widgetMiniButtonIconSize: 24px;

// Chat
$selectiveChatBalloonGap: 12px;
$selectiveContentMinWidth: 200px;
$selectiveChatBalloonDescriptionGap: 6px;
$chatTextFieldGap: 8px;
$chatBalloonStatusWidth: 64px;
$chatBalloonGap: 4px;
$chatBalloonTextPadding: 12px;
$chatBalloonAvatarIcon: 42px;
$chatBalloonParsonIcon: 32px;
$chatBalloonRadius: 16px;
$chatSelectiveBalloonRadius: 8px;
$chatSelectiveBalloonMargin: 10px;
$chatCommentRowGap: 8px;

// ChatWidget
$widgetChatMinWidth: 240px;
$widgetChatTextAreaMinHeight: 135px;
$widgetChatMaxWidth: 324px;
$widgetChatMaxHeight: 600px;

// Widget
// Widget_Header
$widgetHeaderHight: 40px;
$widgetHeaderButtonSize: 28px;
$widgetHeaderButtonIconSize: 11.67px;
$widgetHeaderIconSize: 27px;
$widgetHeaderIconSizeFixed: 24px;

// Widget_Scroll
$scrollbarWidth: 4px;
$scrollbarMinHeight: 180px;

// WIdget_Contents
$widgetContentPadding: 16px;

// WIdget_Footer

$tabletMaxWidth: 960px;
$smartphoneMaxWidth: 520px;

// Badge
$badgeSize: 20px;
$badgeBorder: 2px;

// PrimaryButton
$onlineRoomNewPrimaryButtonIconSize: 28px;
$onlineRoomNewPrimaryButtonSpanHeight: 21px;
$onlineRoomNewPrimaryButtonWidth: 320px;
$onlineRoomNewPrimaryButtonHeight: 64px;

// OnlineRoomEntranceBody
$onlineRoomEntranceBodyInfoSvgWidth: 24px;
$onlineRoomEntranceBodyInfoSvgHeight: 24px;
$onlineRoomNewEntranceBodyGap: 10px;
$onlineRoomNewEntranceBodyWidthFixed: 320px;
$onlineRoomEntranceBodyAvatarBottom: 210px;
$onlineRoomEntranceBodyAvatarBottomShowVideo: 390px;
$onlineRoomEntranceBodyAvatarBottomShowVideoSp: 380px;
$onlineRoomEntranceBodyAvatarLeft: 74px;
$onlineRoomEntranceBodyDummyBoxHeight: 129px;
$onlineRoomEntranceImageWidth: 172px;
$onlineRoomEntranceImageHeight: 172px;
$onlineRoomEntranceImageBottom: -24px;
