// Gradation Color
$backGroundGradation: radial-gradient(67.85% 100% at 50% 0%, #6d7abc 0%, #293250 100%);
$buttonsGradation: linear-gradient(180deg, #95abf7 0%, #6385f2 100%);
$optionGradation: linear-gradient(180deg, #566fc0 0%, #374677 100%);
$screenGradation: linear-gradient(180deg, #3b3d47 0%, #14151a 100%);

// Screen Gradation
$screenGradation: linear-gradient(180deg, #3b3d47 0%, #14151a 100%);
$screenGradationWithOpacity: linear-gradient(180deg, rgba(59 61 71 / 20%) 0%, rgba(20 21 26 / 20%) 100%);

// Screen Gradation
$screenGradation: linear-gradient(180deg, #3b3d47 0%, #14151a 100%);
$screenGradationWithOpacity: linear-gradient(180deg, rgba(59 61 71 / 20%) 0%, rgba(20 21 26 / 20%) 100%);

// Widget Color
$widgetBase: #1a213a;
$widgetBorder: #545b78;
$widgetSelectFrame: rgb(255 255 255 / 20%);

// Option Color
$red: #e43555;
$darkBlue: #374677;
$royalBlue: #5076f1;
$lightSteelBlue: #9eb0eb;
$gray: #d0d0d0;
$white: #fff;
$black: #000;
$deepBlack: #110e22;
$deepBlack20: rgb(17 14 34 / 20%);

// for examples
$exampleBlue: #1ea7fd;
$exampleBlack: #333;
$exampleWhite: #fff;

// transparent
$transparent: transparent;

// Control button option
// TODO: revisit naming after sorting out global style rules.
$controlOptionButtonBase: rgb(0 0 0 / 30%);
$controlOptionButtonHover: rgb(0 0 0 / 50%);

// Control Main Button
// TODO: revisit naming after sorting out global style rules.
$controlMainBaseButtonHover: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), $buttonsGradation;
$controlMainDangerButtonHover: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), $red;
$controlFunctionBaseButtonHover: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), $darkBlue;
$controlFunctionBaseSelectedButtonHover: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), $royalBlue;

// Dialog buttons
$dialogPrimaryButtonHover: linear-gradient(0deg, rgba(0 0 0 / 20%), rgba(0 0 0 / 20%)), $buttonsGradation;

// Widget header button
$widgetButtonHover: rgb(255 255 255 / 10%);

// Scrollbar
$scrollbar: rgba(208 208 208 / 50%);

// TODO: rename $buttonLoginBoxShadow, $inputTextFieldBoxShadow
$buttonLoginBoxShadow: rgb(0 0 0 / 30%);
$inputTextFieldBoxShadow: rgb(0 0 0 / 30%);

// Dialog screen area
$dialogScreenArea: linear-gradient(180deg, #3b3d47 0%, #14151a 100%);

// SectionScreenArea
$screenGraphicsLayer: rgb(0 0 0 / 50%);

// Dialog background
$dialogBackground: rgb(17 14 34 / 80%);
$dialogContainerBackground: rgb(17 14 34 / 20%);
$dialogContainerBackground: rgb(17 14 34 / 20%);

// InformationMessage
$informationMessageBackground: rgb(17 14 34 / 80%);

// Primary buttons
$gradationPrimaryDark: linear-gradient(0deg, rgb(0 0 0 / 10%), rgb(0 0 0 / 10%)),
  linear-gradient(180deg, #95abf7 0%, #6385f2 100%);
$gray100: #d0d0d0;

// Small Button
$smallButtonDefault: rgb(17 14 34 / 50%);
$smallButtonHover: rgb(17 14 34 / 80%);

// Page Not Found
$pageNotFoundBackground: #7299ca;

// Page Error
$pageErrorBackground: #7299ca;

// icon videoCam
$videoCamOff: #747474;

// Large Button
$largeButtonBackgroundDefault: rgb(17 14 34 / 50%);
$largeButtonBackgroundHover: rgb(17 14 34 / 80%);
$largeButtonBackgroundDisable: rgb(255 255 255 / 20%);

// TextField
$textFieldFocusBoxShadowColor: #b7c9fd;

// Sub List Cell
$subListCellBackground: rgb(17 14 34 / 80%);
$subListCellTextDisabled: rgb(208 208 208 / 20%);

// background opacity
$backgroundOpacity: rgb(0 0 0 / 50%);

// SubtitlesContent
$subtitlesContentBackground: rgb(0 0 0 / 80%);

// OnlineRoomEntrance
$onlineRoomEntranceMessageBox: rgba(255 255 255 / 80%);
$onlineRoomEntranceMessageContainer: rgba(255 255 255 / 100%);
$onlineRoomEntranceMessageBoxText: rgba(55 70 119 / 100%);
$onlineRoomEntranceWarningArea: rgb(0 0 0 / 50%);

// DialogAgeVerificationBarcode
$dialogAgeVerificationBarcodeBackground: rgb(0 0 0 / 80%);
$dialogAgeVerificationBarcodeCloseButtonIcon: #374477;
