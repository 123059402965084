@use '../../../styles/colors';
@use '../../../styles/lengths';

.container {
  width: 100%;
  height: 100%;

  .pageNotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: lengths.$pageNotFoundMinSize;
    height: 100%;
    min-height: lengths.$pageNotFoundMinSize;
    background: colors.$pageNotFoundBackground;
  }
}

@media only screen and (max-width: lengths.$pageNotFoundMinSize) {
  .container {
    min-width: unset;
    min-height: unset;
    overflow: auto;
  }
}
